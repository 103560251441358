<template>
  <b-row class="mt-2 justify-content-center">
    <b-col>
      <div class="text-right">
        <b-button
          v-b-modal.add-modal
          variant="primary"
          class="mb-1"
        >
          <span class="text-nowrap">Add SubClassification</span>
        </b-button>
      </div>

      <b-card header="Search">
        <b-row>
          <b-col>
            <search
              placeholder="Name"
              :search-query.sync="searchQuery"
              @refresh="refreshTable"
            />
          </b-col>
          <b-col
            cols="12"
            class="my-1"
          >
            <classification-select :class.sync="classId" />
          </b-col>
          <b-col
            md="2"
            class="mt-1 mt-md-0"
          >
            <search-button @refresh="refreshTable" />
          </b-col>

        </b-row>
      </b-card>

      <b-card>
        <sub-class-table
          ref="parentTable"
          v-bind="{
            getSubClass,
            pagination
          }"
          :sub-class.sync="subClass"
        />
      </b-card>

      <pagination
        :total-rows="pagination.totalRows"
        :per-page="pagination.perPage"
        :current-page.sync="pagination.currentPage"
      />
    </b-col>

    <div class="d-flex align-items-center justify-content-end m-2">
      <!-- modal Add Classification -->
      <b-modal
        id="add-modal"
        centered
        title="Add SubClassification"
        hide-footer
        no-close-on-backdrop
      >
        <add-sub-classifications
          v-bind="{classifications}"
          @refresh="refreshTable"
        />
      </b-modal>

      <!-- Edit -->
      <b-modal
        id="edit-modal"
        centered
        title="Edit SubClassification"
        hide-footer
        no-close-on-backdrop
      >
        <edit-sub-classifications
          v-bind="{classifications,subClass}"
          @refresh="refreshTable"
        />
      </b-modal>
    </div>
  </b-row>
</template>

<script>

import {
  computed,
} from '@vue/composition-api'
import store from '@/store'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import Search from '@/common/components/common/Table/Search.vue'
import deleteRecord from '@/common/compositions/common/deleteRecord'
import AddSubClassifications from '@/common/components/GeneralSettings/sub-classifications/AddSubClassifications.vue'
import EditSubClassifications from '@/common/components/GeneralSettings/sub-classifications/EditSubClassifications.vue'
import SubClassTable from '@/common/components/GeneralSettings/sub-classifications/SubClassTable.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import ClassificationSelect from '@/common/components/common/ClassificationSelect.vue'

export default {
  components: {
    AddSubClassifications,
    EditSubClassifications,
    SubClassTable,
    Pagination,
    Search,
    SearchButton,
    ClassificationSelect,
  },
  data() {
    return {
      subClass: {},
      pagination: {
        totalRows: 5,
        currentPage: 1,
        perPage: 10,
      },
      searchQuery: '',
      classId: '',
    }
  },
  setup() {
    const { confirmDelete, table } = deleteRecord()

    // Get Classifications
    const fetchClassifications = () => store.dispatch('generalSettings/getClassificationRequest')
    fetchClassifications()
    const classifications = computed(() => store.state.generalSettings.classifications)

    return {
      classifications,
      confirmDelete,
      table,
    }
  },
  methods: {
    getSubClass() {
      return this.$entities
        .get(`internalops/sub-classifications?page=${this.pagination.currentPage}`, {
          params: { name: this.searchQuery, classification: this.classId },
        }).then(res => {
          const items = res.data.data.data
          this.pagination.perPage = res.data.data.per_page
          this.pagination.totalRows = res.data.data.total
          return items || []
        }).catch(() => [])
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
