<template>
  <validation-observer
    v-slot="{ handleSubmit }"
  >
    <b-form
      class="p-2"
      @submit.enter.prevent="handleSubmit(addSubClassification)"
    >
      <validation-provider
        #default="validationContext"
        name="Subclassification Name"
        rules="required|min:3"
      >
        <b-form-group
          label="Subclassification Name"
          label-for="classification"
        >
          <b-form-input
            id="classification"
            v-model="subClass.name"
            :state="getValidationState(validationContext)"
            autofocus
            placeholder="Sub Classification Name"
          />
        </b-form-group>
        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>

      <validation-provider
        #default="validationContext"
        name="Subclassification Name"
        rules="required"
      >
        <b-form-group
          label="Select Classification"
          label-for="name"
        >
          <v-select
            id="name"
            v-model="subClass.class_id"
            label="name"
            :state="getValidationState(validationContext)"
            :reduce="cat => cat.id"
            :options="classifications"
          />
        </b-form-group>
        <b-form-invalid-feedback :state="getValidationState(validationContext)">
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>

      <!-- Form Actions -->
      <div class="d-flex mt-2">
        <b-button
          variant="primary"
          type="submit"
        >
          Add
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'AddSubClassification',
  components: {
    vSelect,
  },
  props: {
    classifications: { type: Array, default: () => [] },
  },
  data() {
    return {
      subClass: {},
    }
  },
  setup() {
    // Form Validation
    const {
      getValidationState,
    } = formValidation()

    const { successfulOperationAlert, showErrors } = handleAlerts()

    return {
      getValidationState,
      successfulOperationAlert,
      showErrors,
    }
  },
  methods: {
    addSubClassification() {
      this.$entities.post('internalops/sub-classifications', this.subClass).then(() => {
        this.$bvModal.hide('add-modal')
        this.successfulOperationAlert('Classification is added successfully')
        this.$emit('refresh')
      }).catch(err => {
        this.showErrors(err.response.data.errors)
      })
    },
  },
}
</script>

<style>

</style>
