<template>
  <b-form-group
    label="Select Classification"
    label-for="class"
  >
    <v-select
      id="class"
      label="name"
      :reduce="classification => classification.id"
      :options="classifications"
      @input="onSelectClass"
    />
  </b-form-group>
</template>
<script>
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  name: 'ClassificationSelect',
  components: {
    vSelect,
  },
  data() {
    return {
      classifications: [],
    }
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()
    return { getValidationState }
  },
  created() {
    this.getClassifications()
  },
  methods: {
    getClassifications() {
      this.$entities.get('internalops/classifications').then(res => {
        this.classifications = res.data.data.data
      })
    },
    onSelectClass(classId) {
      this.$emit('update:class', classId)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
