<template>
  <validation-observer
    v-slot="{ handleSubmit }"
  >
    <b-form
      class="p-2"
      @submit.enter.prevent="handleSubmit(updateSubClassification)"
    >
      <validation-provider
        #default="validationContext"
        name="Name"
        rules="required|min:3"
      >
        <b-form-group
          label="Name"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="subClass.name"
            :state="getValidationState(validationContext)"

            autofocus
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider
        #default="validationContext"
        name="Classification"
        rules="required"
      >
        <b-form-group
          label="Select Classification"
          label-for="cat"
        >
          <v-select
            v-model="subClass.class_id"
            label="name"
            :reduce="subclass => subclass.id"
            :state="getValidationState(validationContext)"
            :options="classifications"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <!-- Form Actions -->
      <div class="d-flex mt-2">
        <b-button
          variant="primary"
          type="submit"
        >
          Save
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'EditSubClassification',
  components: {
    vSelect,
  },
  props: {
    subClass: {
      type: Object,
      default: () => {},
    },
    classifications: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()
    const { successfulOperationAlert, showErrors } = handleAlerts()
    return {
      successfulOperationAlert, showErrors, getValidationState,

    }
  },
  methods: {
    updateSubClassification() {
      this.$entities.post(`internalops/sub-classifications/${this.subClass.id}?_method=PUT`, {
        name: this.subClass.name,
        class_id: this.subClass.class_id,
      }).then(() => {
        this.$bvModal.hide('edit-modal')
        this.successfulOperationAlert('Classification is updated successfully')
        this.$emit('refresh')
      }).catch(err => {
        this.showErrors(err.response.data.errors)
      })
    },
  },
}
</script>

<style>

</style>
